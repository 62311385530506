<template>
  <SignUpScreen>
    <div class="text-center fit-content-width mx-auto">
      <h6 class="caps mt-xl mb-lg">
        What’s next?
      </h6>
      <h2 class="mb-lgp">
        {{ displayedSteps.header }}
      </h2>
      <div
        v-for="(step, index) in displayedSteps.steps"
        :key="step.title">
        <div class="step-number-button bg-plum text-white d-flex justify-content-center align-items-center mx-auto mb-md">
          <h6 class="mb-0">
            {{ index + 1 }}
          </h6>
        </div>

        <h5>{{ step.title }}</h5>
        <p class="mb-lg">
          {{ step.description }}
        </p>
        <div
          v-if="membershipPlanSelected && bonusItemsPerk && index === 1"
          class="bg-orchid text-white px-3 py-2 d-flex justify-content-between align-items-center mb-lgp">
          <SvgOrigamiDressFixed v-if="isTabletOrLarger"/>
          <span class="mx-auto">
            <h5 class="text-white">{{ newMemberPerkMessage.title }}</h5>
            <p v-html="newMemberPerkMessage.description"/>
          </span>
          <SvgOrigamiDressFixed v-if="isTabletOrLarger"/>
        </div>
      </div>
      <div>
        <a
          v-for="platform in filteredPlatforms"
          :key="platform.id"
          class="app-download-link"
          target="_blank"
          :href="platform.link">
          <img
            :alt="platform.alt"
            :src="platform.icon">
        </a>
      </div>
    </div>
  </SignUpScreen>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia'
import SignUpScreen from '@/views/signUp/SignUpScreen.vue'
import { useAppStoreLinks } from '@shared/composables/appStoreLinks.js'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { usePlans } from '@shared/composables/plans.js'
import SvgOrigamiDressFixed from '@/components/global/svg/SvgOrigamiDressFixed.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import { useClientStore } from '@shared/stores/client.js'
import { inMobileWebView } from '@/utils/mobileApp'

const { isTabletOrLarger } = useScreenSize()
const subscriptionStore = useSubscriptionStore()
const { membershipPlanSelected } = storeToRefs(subscriptionStore)
const { initializeMembershipPlans } = usePlans()
const { membershipStatus } = storeToRefs(useClientStore())
const { showApple, showAndroid, platforms } = useAppStoreLinks()

const filteredPlatforms = platforms.filter((platform) => (platform.id === 'ios' && showApple) || (platform.id === 'android' && showAndroid))

const newMemberDetails = ref({
  header: "Let's get started",
  steps: [
    {
      title: 'Browse Your Closet',
      description: 'Your first curated Closet is ready! Click the heart icon on clothes you love so we can better learn your style.'
    },
    {
      title: 'Fill Your First Case',
      description: 'Hit “Add to Case” on the items you want now. When all spots are filled, you’re ready to check out.'
    },
    {
      title: 'Download The Mobile App',
      description: 'Unlock the best of Armoire – browse your Closet from anywhere, easily swap items for new ones, and more.'
    }
  ]
})
const rejoinMemberDetails = ref({
  header: 'Welcome back',
  steps: [
    {
      title: 'Build your case',
      description: 'Browse your Closet, read reviews, and add items to your Case before checking out.'
    },
    {
      title: 'Download the mobile app',
      description: 'Get real-time notifications about your Case and review styles on-the-go.',
      showAppLink: true
    },
    {
      title: 'Favorite styles for better recommendations',
      description: 'Select the heart icon on styles you love. Your recommendations will get more personalized as you share your preferences.'
    }
  ]

})
const quizRetakeDetails = ref({
  header: 'Thanks for helping us learn more about you!',
  steps: [
    {
      title: 'Build your next case',
      description: 'Check out your refreshed closet to select items for your next Case.'
    },
    {
      title: 'Rate while you wait',
      description: 'Favorite the styles you see in your closet, this helps us get smarter and more personalized with our recommendations.'
    }
  ]
})
const isNonMember = computed(() => !membershipStatus.value || membershipStatus.value === 'non_member')
const isQuizRetake = computed(() => isNonMember.value || inMobileWebView())
const isRejoiningMember = computed(() => membershipStatus.value === 'former_member')
const displayedSteps = computed(() => isQuizRetake.value ? quizRetakeDetails.value : isRejoiningMember.value ? rejoinMemberDetails.value : newMemberDetails.value)
const bonusItemsPerk = computed(() => membershipPlanSelected.value?.appliedPromo?.bonusItemsOnSignup)
const newMemberPerkMessage = computed(() => {
  const items = 2
  return {
    title: 'New Member Perk!',
    description: 'We’re treating you to ' + items + ' additional item' + (items > 1 ? 's' : '') + ' of <br>your choice for your first Case.'
  }
})
onBeforeMount(async () => {
  await initializeMembershipPlans()
})
</script>

<style scoped lang="scss">
.step-number-button {
  border-radius: 50%;
  background-color: $plum;
  width: 36px;
  height: 36px;
  color: $white;
}
.app-download-link {
  margin: 0 16px;
  img {
    width: 132px;
    height: auto;
  }
}
</style>
